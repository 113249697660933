@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .linear-bg {
    border-radius: 16px;
    border: 1px solid #A246FF;
    background: linear-gradient(180deg, rgba(162, 70, 255, 0.40) 0%, rgba(162, 70, 255, 0.00) 100%), #000;
  }

  .linear-bg-berachain {
    border-radius: 16px;
    border: 1px solid #FFA800;
    background: linear-gradient(180deg, rgba(255, 168, 0, 0.4) 0%, rgba(255, 168, 0, 0) 100%), linear-gradient(0deg, #000000, #000000);
  }

  .linear-modal {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0.16%, rgba(112, 0, 255, 0.36) 34.4%, rgba(139, 120, 253, 0.62) 64.82%, #C89DFF 85.77%, #AC51D7 104.54%);
    backdrop-filter: blur(3px);
  }

  .break-linear {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 52.07%, rgba(255, 255, 255, 0.00) 100%);
  }

  .loader-animation {
    width: 80px;
    height: 80px;
    border: 5px solid #292929;
    border-bottom-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  .linear-modal-bg {
    background: rgba(219, 255, 115, 0.10);
  }
}

@layer components {
  .lds-ring-v2 div {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color);
    border-radius: 50%;
    animation: lds-ring2 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color) transparent transparent transparent;
  }

  .lds-ring-v2 div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring-v2 div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring-v2 div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring2 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

}


@layer utilities {
  .radio-background {
    border-top: 1px solid #242424;
    border-bottom: 1px solid #242424;
    background: radial-gradient(50% 50.02% at 50% 0%, #2F2F2F 0%, #141414 33.33%, #0E0E0E 66.67%, #000 100%);
  }
}

.highlight {
  background-image: url("./assets/png/square.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.marquee__content {
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}